<template>
    <PrimeDrawer v-model:visible="visible" :showCloseIcon="false">
        <div class="w-lvw bg-slate-500" @click.self="visible = false">
            <div class="h-[100vh] bg-white-700 w-[560px]">
                <div class="h-[var(--size-header-height)] border-b border-slate-400 flex justify-between items-center px-9">
                    <h1>Personal Concierge</h1>

                    <div
                        class="bg-slate-100 p-1 rounded-full cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                        @click="visible = false"
                    >
                        <XMarkIcon class="h-5 w-5 text-slate-700" />
                    </div>
                </div>

                <div class="p-9 grid gap-6">
                    <p>
                        Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.
                    </p>

                    <prime-textarea placeholder="Lorem ipsum dolor sit amet." rows="5" class="w-full"></prime-textarea>

                    <div class="flex justify-between items-center">
                        <span
                            class="text-style-link !text-blue-700 cursor-pointer hover:bg-blue-100 px-3 h-full flex items-center justify-center rounded active:bg-blue-200"
                            >Cancel</span
                        >
                        <prime-button varaint="primary"> Submit </prime-button>
                    </div>
                </div>
            </div>
        </div>
    </PrimeDrawer>

    <div class="nav-item" @click="visible = true">
        <div class="flex gap-3 items-center">
            <support-icon class="stroke-yellow-700" />
            <span class="text-style-link">Concierge</span>
        </div>
    </div>
</template>

<script setup>
import PrimeDrawer from 'primevue/drawer';
import PrimeTextarea from 'primevue/textarea';
import LeftnavItem from '../LeftNav/LeftnavItem.vue';
import SupportIcon from '~/assets/icons/support.svg';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const visible = ref(false);
</script>

<style lang="scss" scoped>
.nav-item {
    @apply flex py-3 items-center gap-3 justify-between cursor-pointer px-6;
    transition: all ease-in-out 0.1s;

    &:hover {
        @apply bg-blue-300;
    }

    span {
        @apply text-white-700 leading-none;
    }
}
</style>
