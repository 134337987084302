<template>
    <DynamicDialog />

    <div>
        <div class="hidden lg:block"><personal-navigation /></div>

        <div class="layout-container">
            <app-header :has-border="hasBorder" />

            <div id="content-container">
                <slot />
            </div>

            <app-footer :has-double-sidebar="route.meta.pageGroup == 'trust' && auth_store.loggedIn" v-if="!isContact" />
        </div>
    </div>
</template>

<script setup>
import AppFooter from '~/components/layouts/AppFooter.vue';
import PersonalNavigation from '~/components/layouts/PersonalNavigation.vue';
import AppHeader from '~/components/layouts/AppHeader.vue';
import DynamicDialog from 'primevue/dynamicdialog';

const route = useRoute();
const auth_store = useAuthStore();

const hasBorder = computed(() => {
    return route.name !== 'Manage Account' && route.name !== 'Active Exposures';
});

const isContact = computed(() => {
    return route.path.includes('/contact');
});
</script>

<style lang="scss" scoped>
.layout-container {
    @apply lg:ml-[280px] flex flex-col min-h-screen bg-white-700;
}

#content-container {
    @apply flex-1 mt-[var(--size-header-height)];
}
</style>
